import { layout } from './layout';
import { createBlazorApi } from 'piral-blazor'
import { errors } from './components/Errors';
import { Route, Switch } from 'react-router';
import { createRoot } from 'react-dom/client';
import { SwitchToLayoutDefault, SwitchToLayoutEmpty, SwitchToLayoutNoSidebar } from './layoutSwitchers';
import * as React from 'react';
import { Piral, createInstance, SetComponent } from 'piral-core';
import { standardStrategy } from 'piral-base';
import { LoadingSpinner } from './components/LoadingSpinner';
import "./plugins/monaco"
import { createLocaleApi } from 'piral-translate';

// if (process.env.DEBUG_PILET) {
//   sessionStorage.setItem('dbg:load-pilets', 'on')
// }

const host = window.location.host
const subdomain = host.split('.')[0]

let feedUrl = "";
if(subdomain.includes("localhost"))
{
  feedUrl = 'https://dev-api.casablanca.at/feed/api/v1/pilet/casablanca-cloud';
}
else
{
  switch (subdomain) {
    case "dev-cloud":
      feedUrl = 'https://dev-api.casablanca.at/feed/api/v1/pilet/casablanca-cloud';
    break;
  case "staging-cloud":
    feedUrl = 'https://staging-api.casablanca.at/feed/api/v1/pilet/casablanca-cloud';
    break;
  default:
    feedUrl = 'https://api.casablanca.at/feed/api/v1/pilet/casablanca-cloud';
    break;
  }
}

window.addEventListener("contextmenu", event => {
  if(!event.ctrlKey){
    event.preventDefault();
  }
}); 

function getInitialLanguage(): string{
  let result = localStorage.getItem("culture"); 
  if(!result){
    result = navigator.language
  } else{
    result = result.replace(/"/g, '');
  }
  return result
}

const instance = createInstance({
  state: {
    components: layout,
    errorComponents: errors,
  },
  plugins: [
    createLocaleApi(),
    createBlazorApi({
      lazy: false,
      logLevel: 3,
      initialLanguage: getInitialLanguage(),
      onLanguageChange: callback => {}
    }),
  ],
  requestPilets() {
    return fetch(
      feedUrl,
      {
        headers:{
          "x-user-objectid" : sessionStorage.getItem("authenticationStateContainer.currentUserObjectId"),
          "x-user-casablancaid" : sessionStorage.getItem("authenticationStateContainer.currentTenantId"),
          "x-user-organizationId" : sessionStorage.getItem("authenticationStateContainer.organizationId"),
          "x-user-iscbemployee" : sessionStorage.getItem("authenticationStateContainer.iscbemployee"),
        }
      }
    )
    .then((res) => res.json())
    .then((res) => res.items);
  },
  async: (options, pilets) => {
    standardStrategy(options, pilets);
    return new Promise(resolve => {
      window.addEventListener('loaded-blazor-pilet', (ev: CustomEvent) => {
        if (ev.detail.name === '@casablanca/cloud-ui') {
          // The layout has been loaded completely. We can now hide the spinner and show the app.
          resolve();
        }
      });
    });
  },
});

const root = createRoot(document.querySelector('#app'));

root.render(<Piral instance={instance}>
  <SetComponent name="LoadingIndicator" component={LoadingSpinner} />
  <Switch> 
   <Route path="/:tenantId" exact component={SwitchToLayoutNoSidebar} />
   <Route path="/users/current" exact component={SwitchToLayoutNoSidebar} />
   <Route path="/iam/resources" exact component={SwitchToLayoutNoSidebar} />
   <Route path="/iam/organizations" exact component={SwitchToLayoutNoSidebar} />
   <Route path="/authentication/*" component={SwitchToLayoutEmpty} />
   <Route path="/" exact component={SwitchToLayoutNoSidebar} />
   <Route path="/" component={SwitchToLayoutDefault} />
 </Switch>
</Piral>);
